import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/Carousel.css';

const Home = () => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  const additionalSolutions = [
    {
      title: "Comprehensive Market Analysis",
      description: "See where you stack up against your competitors on rates, and identify which payors to target for increases. Go into your rate negotiation armed with the rates that your payor has negotiated with all your competitors.",
      imageSrc: "/Data_Analysis_Image.png",
      imageAlt: "Market analysis illustration"
    },
    {
      title: "Network Adequacy Evaluation",
      description: "Use our network adequacy report to show the payor the cost of losing you from their network. Our report includes per-county % adequacy of the payor's network, estimated enrollee count for ABA services, and in-network provider availability based on state mandated distance standards.",
      imageSrc: "/Network_Adequacy.png",
      imageAlt: "Network adequacy evaluation"
    },
    {
      title: "Reimbursement Advocacy",
      description: "We provide powerful data-driven insights to support your reimbursement negotiations. Our reports highlight market trends, competitive rates, and network adequacy to strengthen your position with payors.",
      imageSrc: "/Advocacy_Image.png",
      imageAlt: "Reimbursement advocacy illustration"
    }
  ];

  return (
    <>
      {/* Hero Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-primary text-white">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center space-y-4 text-center">
            <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
              Atlas Medical Data Group
            </h1>
            <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl dark:text-gray-400">
              Understand your market and negotiate with confidence.
            </p>
            <Link
              to="/about#top"
              className="inline-flex h-12 items-center justify-center rounded-md bg-white px-8 text-sm font-medium text-primary shadow transition-colors hover:bg-gray-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
            >
              Learn More
            </Link>
          </div>
        </div>
      </section>

      {/* Excel Template Section */}
      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <div className="grid gap-6 lg:grid-cols-[1fr_500px] lg:gap-12 xl:grid-cols-[1fr_700px]">
            <div className="relative w-full h-0 pb-[75%] lg:order-last overflow-hidden rounded-2xl shadow-md">
              <img
                alt="Excel Iceberg Visualization"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src="/Excel_Iceberg.png"
              />
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Configurable Excel Template</h2>
              <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                Our configurable Excel template lets you view negotiated rates for every payor and individual competitor in your area per CPT code, and seamlessly integrate this data with your existing systems, helping you identify growth areas and make data-driven decisions with real-time insights.
              </p>
              <ul className="grid gap-2 py-4">
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-4 w-4 text-green-500" />
                  <span>Real-time data access and analysis</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-4 w-4 text-green-500" />
                  <span>Customizable data categorization</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-4 w-4 text-green-500" />
                  <span>Secure cloud connectivity</span>
                </li>
              </ul>
              <div className="flex justify-center">
                <Link
                  className="inline-flex h-12 items-center justify-center rounded-md bg-primary px-8 text-sm font-medium text-white shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300 w-40"
                  to="/excel-template"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Additional Solutions Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
        <div className="container px-4 md:px-6">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-8 text-center">Additional Solutions</h2>
          <div className="max-w-4xl mx-auto">
            <Slider {...carouselSettings} className="max-w-3xl mx-auto">
              {additionalSolutions.map((solution, index) => (
                <div key={index} className="px-4">
                  <div className="flex flex-col bg-white rounded-2xl shadow-md overflow-hidden h-[600px]">
                    <div className="p-6 flex-shrink-0">
                      <h3 className="text-2xl font-bold mb-2">{solution.title}</h3>
                      <p className="text-gray-500 mb-4">{solution.description}</p>
                    </div>
                    <div className="relative flex-grow">
                      <img 
                        src={solution.imageSrc}
                        alt={solution.imageAlt}
                        className="absolute inset-0 w-full h-full object-cover object-center" 
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      {/* Our Process Section */}
      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-8 text-center">Our Process</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                title: "Consume Data",
                description: "We collect data from numerous payor sites, processing thousands of 'Machine Readable Files' with our custom high-performance software."
              },
              {
                title: "Filter Data",
                description: "We filter the data to relevant billing codes and regions, removing 'ghost network' providers to show only active billers."
              },
              {
                title: "Enhance Data",
                description: "We add competitor details, credentialing information, single case agreement frequencies, and licensure data."
              },
              {
                title: "Curate Data",
                description: "We deliver easy-to-understand, monthly-updated data via cloud-hosted Excel, integrating seamlessly with your systems."
              }
            ].map((step, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-primary text-white">
        <div className="container px-4 md:px-6 text-center">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-4">
            Ready to transform your data into actionable insights?
          </h2>
          <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl mb-6">
            Join Atlas Medical Data Group today and unlock the power of data-driven decision making for your practice.
          </p>
          <Link
            to="/contact"
            className="inline-flex h-12 items-center justify-center rounded-md bg-white px-8 text-sm font-medium text-primary shadow transition-colors hover:bg-gray-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
          >
            Contact Us
          </Link>
        </div>
      </section>
    </>
  );
};

export default Home;