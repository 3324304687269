import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import CookieNotice from './components/CookieNotice';
import Home from './pages/Home';
import ExcelTemplate from './pages/ExcelTemplate';
import WhoWeServe from './pages/WhoWeServe';
import About from './pages/About';
import Contact from './pages/Contact';

const App = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleHeaderVisibilityChange = useCallback((visible) => {
    setIsHeaderVisible(visible);
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header onVisibilityChange={handleHeaderVisibilityChange} />
        <main className={`flex-grow transition-padding duration-300 ${!isMobile && isHeaderVisible ? 'pt-[88px]' : 'pt-0'}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/excel-template" element={<ExcelTemplate />} />
            <Route path="/who-we-serve" element={<WhoWeServe />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
        <CookieNotice />
      </div>
    </Router>
  );
};

export default App;
