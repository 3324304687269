import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, Lock, Shield, RefreshCw } from 'lucide-react';

const ExcelTemplate = () => {
  return (
    <main className="flex-1">
      <section className="w-full py-12 md:py-24 lg:py-32 bg-primary text-white">
        <div className="container px-4 md:px-6">
          <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-center">
            Excel Template for Behavioral Health Data
          </h1>
          <p className="mt-4 text-xl text-center text-gray-200">
            Harness the power of familiar tools with advanced analytics
          </p>
        </div>
      </section>

      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <div className="grid gap-6 lg:grid-cols-[1fr_500px] lg:gap-12 xl:grid-cols-[1fr_700px]">
            <div className="flex flex-col justify-center space-y-4">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl">Familiar Format, Powerful Insights</h2>
              <p className="text-gray-500 md:text-xl">
                Our Excel template brings the power of data analytics to a format you're already comfortable with. By leveraging the familiar Excel interface, we make it easy for you to input, analyze, and visualize your behavioral health clinic data. We do our magic behind the scenes and serve you the data in a format you know.
              </p>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>Easy data entry and organization</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>Custom dashboards to highlight key metrics</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>Seamlessly integrates into your existing workflow</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>Over 4 trillion records processed monthly</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>2 petabytes of data cleaned and enriched each month</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>Proprietary data enrichment</span>
                </li>
              </ul>
            </div>
            <div className="lg:order-last overflow-hidden rounded-2xl shadow-md">
              <img
                src="/Excel_Screenshot_Web_Content copy.png"
                alt="Excel template data visualization"
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-6 md:py-12 lg:py-16 bg-gray-50">
        <div className="container">
          <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl mb-6 text-center text-[#0a2342]">Key Features</h2>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-[#0a2342]">Data Visualization</h3>
              <p className="text-gray-600">Transform complex data into easy-to-understand charts and graphs.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-[#0a2342]">Automated Calculations</h3>
              <p className="text-gray-600">Save time with pre-built formulas tailored to behavioral health metrics.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-[#0a2342]">Customizable Reports</h3>
              <p className="text-gray-600">Generate professional reports with just a few clicks.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-6 md:py-12 lg:py-16">
        <div className="container">
          <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl mb-6 text-center text-[#0a2342]">Secure and Compliant</h2>
          <p className="text-gray-600 text-center max-w-2xl mx-auto mb-8">
            Your data's security is our top priority. Our Excel template is backed by a robust cloud infrastructure, ensuring that your sensitive information is always protected and accessible only to authorized personnel.
          </p>
          <ul className="grid gap-4 md:grid-cols-3">
            <li className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
              <Lock className="h-8 w-8 text-[#0a2342] mb-2" />
              <span className="text-center">End-to-end encryption</span>
            </li>
            <li className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
              <Shield className="h-8 w-8 text-[#0a2342] mb-2" />
              <span className="text-center">HIPAA compliance</span>
            </li>
            <li className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
              <RefreshCw className="h-8 w-8 text-[#0a2342] mb-2" />
              <span className="text-center">Regular backups</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="w-full py-6 md:py-12 lg:py-16">
        <div className="container text-center">
          <Link to="/" className="bg-[#0a2342] text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-[#0a2342]/90 transition-colors">
            Back to Home
          </Link>
        </div>
      </section>
    </main>
  );
}

export default ExcelTemplate;