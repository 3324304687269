import React from 'react';

const About = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 bg-primary text-white">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  About Us
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl">
                  At Atlas Medical Data Group, we empower ABA clinics and behavioral healthcare providers by harnessing the full potential of the Transparency in Coverage rule. This regulatory initiative provides unprecedented access to health insurance pricing data, offering providers the tools they need to negotiate more favorable rates and make informed decisions about patient care.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-6 md:py-12">
          <div className="container px-4 md:px-6">
            <div className="mx-auto max-w-[900px] text-center">
              <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl mb-2 text-primary">Transparency in Coverage Rule</h2>
              <p className="text-gray-600 md:text-lg">
                The Transparency in Coverage rule, finalized by the Centers for Medicare & Medicaid Services (CMS) in 2020, requires health insurance plans to disclose detailed pricing information. This rule aims to foster greater transparency in healthcare pricing, enabling both providers and patients to make informed financial decisions.
              </p>
            </div>
          </div>
        </section>

        <section className="w-full py-6 md:py-12 bg-gray-50">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 mx-auto max-w-[900px]">
              <div className="space-y-2">
                <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl text-primary">How We Use Transparency Data</h2>
                <p className="text-gray-600 md:text-lg">
                  At Atlas Medical Data Group, we use this data to perform <span className="font-semibold text-primary">network analysis</span>, allowing providers to understand the availability of care in markets across the United States. This analysis helps ABA clinics and behavioral health providers identify where care gaps exist and how to address them, improving access to essential services. In addition, we leverage machine-readable files published by health insurance plans to extract critical data.
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl text-primary">Key Data We Provide</h2>
                <ul className="list-disc text-left pl-6 text-gray-600 md:text-lg">
                  <li><strong className="text-primary">In-network negotiated rates:</strong> Understand how much you can expect to be reimbursed for covered ABA and behavioral health services.</li>
                  <li><strong className="text-primary">Out-of-network billing and payments:</strong> Gain insights into reimbursement trends for out-of-network services.</li>
                </ul>
                <p className="text-gray-600 md:text-lg mt-4">
                  Our proprietary technology transforms this raw data into actionable insights, enabling smarter financial and operational decisions for clinics and providers.
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl text-primary">Key Milestones in the Rule's Implementation</h2>
                <ul className="list-disc text-left pl-6 text-gray-600 md:text-lg">
                  <li><strong className="text-primary">July 1, 2022:</strong> Insurance plans were required to post machine-readable files with in-depth pricing data.</li>
                  <li><strong className="text-primary">January 1, 2023:</strong> Plans launched price-comparison tools for consumers, covering 500 shoppable services.</li>
                  <li><strong className="text-primary">January 1, 2024:</strong> These tools now offer cost-sharing estimates for all covered services, making pricing even more transparent.</li>
                </ul>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl text-primary">Our Mission</h2>
                <p className="text-gray-600 md:text-lg">
                  We are committed to helping ABA clinics and behavioral health providers overcome barriers that lead to long wait lists for quality care. By leveraging transparency data and performing network analysis, we help clinics identify areas where care availability is lacking, improving the distribution of services.
                </p>
                <p className="text-gray-600 md:text-lg">
                  By turning data into insights, we aim to reduce the administrative burden on healthcare providers, allowing them to focus more on patient outcomes and less on the complexities of insurance.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="mx-auto max-w-[900px] text-center">
              <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl mb-4 text-primary">Proprietary Data Enhancement</h2>
              <p className="text-gray-600 md:text-lg mb-6">
                Our proprietary data enhancement process significantly increases accuracy for healthcare providers. By leveraging advanced algorithms and machine learning techniques, we clean, normalize, and enrich raw data from various sources, including the Transparency in Coverage files.
              </p>
              <ul className="list-disc text-left pl-6 text-gray-600 md:text-lg space-y-2">
                <li>Data cleansing to remove inconsistencies and errors</li>
                <li>Normalization of data across different payers and providers</li>
                <li>Enrichment with additional relevant information from trusted sources</li>
                <li>Machine learning models to identify patterns and trends</li>
                <li>Continuous improvement through feedback loops and data validation</li>
              </ul>
              <p className="text-gray-600 md:text-lg mt-6">
                This enhanced data provides our clients with a more accurate and comprehensive view of the healthcare landscape, enabling better decision-making and more effective negotiations with payers.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;