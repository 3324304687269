import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CookieNotice = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('cookiesAccepted');
    if (!hasAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-2 text-sm">
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
        <p className="mb-2 sm:mb-0 sm:mr-4">
          We use cookies to improve your experience. By using our site, you agree to our use of cookies.
        </p>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleAccept}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded text-sm"
          >
            Accept
          </button>
          <Link to="/privacy-policy" className="text-blue-300 hover:text-blue-100 text-sm">
            Privacy Policy
          </Link>
          <Link to="/cookie-policy" className="text-blue-300 hover:text-blue-100 text-sm">
            Cookie Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CookieNotice;
