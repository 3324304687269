import React, { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    // Create a script element for the HubSpot forms embed code
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    document.head.appendChild(script);

    // Once the script is loaded, create the form
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "47663774",
          formId: "55bb5f95-774e-49ed-8a01-c979dda98603",
          target: '#hubspotForm'
        });
      }
    };

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <main className="flex-1">
        <section className="w-full py-12 md:py-16 lg:py-20 bg-[#0a2342] text-white">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Contact Us
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl">
                  We're here to answer any questions you may have about our services.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-8 md:py-12 lg:py-16">
          <div className="container px-4 md:px-6">
            <div className="mx-auto max-w-[800px] bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-bold mb-6 text-center text-[#0a2342]">Contact Form</h2>
              <p className="text-gray-600 mb-8 text-center">Fill out the form and our team will get back to you within 24 hours.</p>
              <div id="hubspotForm"></div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Contact;
