import React from 'react';

const WhoWeServe = () => {
  const clientTypes = [
    {
      title: "ABA Centers",
      description: "We empower Applied Behavior Analysis (ABA) centers with data-driven solutions to optimize operations, enhance patient care, and navigate complex insurance reimbursements.",
      benefits: [
        "Real-time and accurate visibility into rates",
        "Insights into market rates for services",
        "Tools for efficient resource allocation",
        "Focus on network adequacy",
        "Negotiate with confidence"
      ]
    },
    {
      title: "Behavioral Health Providers",
      description: "Our services cater to a wide range of behavioral health professionals, including psychologists, psychiatrists, and mental health counselors.",
      benefits: [
        "Enhanced practice management tools",
        "Data-driven patient care insights",
        "Simplified insurance navigation",
        "Ghost network solution: See through inaccurate health plan directories"
      ]
    },
    {
      title: "Investors and Private Equity",
      description: "We provide in-depth market understanding and competitive landscape analysis for investors seeking opportunities in the healthcare sector, supporting data-driven investment decisions and risk assessment.",
      benefits: [
        "Comprehensive market analysis",
        "Competitive landscape insights",
        "Data-driven investment decision support",
        "Detailed market trends and forecasts",
        "Risk assessment tools",
        "Performance benchmarking data"
      ]
    }
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-8 md:py-16 lg:py-24 bg-[#0a2342] text-white">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Who We Serve
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl">
                  We empower healthcare industry stakeholders with transparency, with a heavy focus on creating value for our customers.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-6 md:py-12">
          <div className="container px-4 md:px-6">
            <div className="mx-auto max-w-[900px] space-y-8">
              {clientTypes.map((client, index) => (
                <div key={index} className="space-y-2">
                  <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl text-[#0a2342]">{client.title}</h2>
                  <p className="text-gray-600 md:text-lg">{client.description}</p>
                  <ul className="list-disc pl-6 text-gray-600 md:text-lg space-y-2">
                    {client.benefits.map((benefit, benefitIndex) => (
                      <li key={benefitIndex}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="w-full py-6 md:py-12 bg-gray-50">
          <div className="container px-4 md:px-6">
            <div className="mx-auto max-w-[900px] text-center">
              <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl md:text-4xl mb-4 text-[#0a2342]">How We Help</h2>
              <p className="text-gray-600 md:text-lg mb-6">
                Our comprehensive solutions are designed to address the unique challenges faced by behavioral health providers:
              </p>
              <ul className="list-disc text-left pl-6 text-gray-600 md:text-lg space-y-2">
                <li>Streamline insurance reimbursement processes</li>
                <li>Optimize practice operations with data-driven insights</li>
                <li>Improve patient care through better resource allocation</li>
                <li>Navigate the complexities of the Transparency in Coverage rule</li>
                <li>Identify market opportunities and growth areas</li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default WhoWeServe;