import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ onVisibilityChange }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/excel-template', label: 'Excel Template' },
    { path: '/who-we-serve', label: 'Who We Serve' },
    { path: '/about', label: 'About' },
    { path: '/contact', label: 'Contact Us' }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== 'undefined' && !isMobile) {
        if (window.scrollY > lastScrollY + 10) {
          setIsVisible(false);
        } else if (window.scrollY < lastScrollY - 10) {
          setIsVisible(true);
        }
        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== 'undefined' && !isMobile) {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY, isMobile]);

  useEffect(() => {
    onVisibilityChange(isVisible);
  }, [isVisible, onVisibilityChange]);

  const headerClasses = `bg-white shadow-sm z-50 transition-transform duration-300 ${
    isMobile
      ? ''
      : `fixed top-0 left-0 right-0 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`
  }`;

  return (
    <header className={headerClasses}>
      <nav className="container mx-auto px-4 py-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img 
              src="/AMDG_Logo_Only.png" 
              alt="AMDG Logo" 
              className="h-12 w-auto mr-4"
            />
            <Link to="/" className="text-2xl font-bold text-[#0a2342]">
              Atlas Medical Data Group
            </Link>
          </div>
          <ul className="flex flex-wrap justify-center md:justify-end space-x-2 md:space-x-4 w-full md:w-auto">
            {navItems.map((item) => (
              <li key={item.path} className="my-1">
                <Link
                  to={item.path}
                  className={`px-3 py-2 rounded-md transition-colors duration-200 ease-in-out ${
                    location.pathname === item.path 
                      ? 'bg-[#0a2342] text-white hover:bg-[#0a2342]/90' 
                      : 'text-[#0a2342] hover:bg-[#0a2342]/10'
                  }`}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
